import React from "react";
import "./footer.css";
export default function Footer() {
  return (
    <div className="footer">
      <p>
        Disclaimer: I do not own the songs listed here. I just listen and use my
        magical backend to get the data. All the music is owned by their awesome
        Creators / Singers / Spotify.
      </p>
      <p>
        Thanks for the{" "}
        <span role="img" aria-label="heart emoji">
          ❤️
        </span>
        . Keep coming here :) Also, if you like the idea, drop a{" "}
        <span role="img" aria-label="star emoji">
          ⭐
        </span>{" "}
        at the{" "}
        <a
          href="https://github.com/anamritraj/spot-connect"
          target="_blank"
          rel="noopener noreferrer"
        >
          Github Repository
        </a>
        . Hit me up at{" "}
        <a
          href="https://twitter.com/anamritraj"
          target="_blank"
          rel="noopener noreferrer"
        >
          @anamritraj
        </a>
        .
      </p>
    </div>
  );
}
